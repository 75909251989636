export function listWithAnd(list) {
    if (list.length === 1) { return list[0]; }
    return list.slice(0, -1).join(', ') + ' & ' + list.slice(-1);
}

export function extractQueryParam(search, paramName) {
    const searchParams = new URLSearchParams(search);
    return searchParams.get(paramName);
}

export function appendDefault(map, key, value) {
    !map.has(key) ? map.set(key, [value]) : map.get(key).push(value);
}
