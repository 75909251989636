// Import Bootstrap
import '../scss/styles.scss' // SCSS
import * as bootstrap from 'bootstrap' // JS
import i18next from 'i18next';

import { getExpressedLanguagePreference, setLanguage } from './internationalisation';
import { extractQueryParam, listWithAnd } from './utils';


const anchoredSections = [...window.document.getElementsByTagName("section")].map(s => s.id);

function targetAnchorOrDefault(locationHash) {
    return locationHash ? locationHash.substring(1) : anchoredSections[0];
}

function updateTabSelection(target) {
    anchoredSections.forEach(anchor => {
        document.getElementById(anchor + '-link').setAttribute('class', anchor === target ? 'nav-link active' : 'nav-link');
        document.getElementById(anchor).setAttribute('class', anchor === target ? 'active' : '');
    });
}

function fetchUserInformation(userCode) {
    return fetch(`/guests/${userCode}.json`)
        .then(response => {
            if (response.status === 200) {
                return response.json()
            } else if (response.status === 404) {
                throw new Error(i18next.t('unknown-code'));
            } else {
                throw new Error(i18next.t('unexpected-error'));
            }
        });
}

const eveningItems = ['music', 'cake-and-first-dance', 'evening-light-bites', 'last-orders', 'carriages'];

function updatePages(userInfo) {
    // Update code search
    const codeSearchInput = window.document.getElementById('code-input');
    codeSearchInput.setAttribute('placeholder', i18next.t('code-input-placeholder'));

    if (userInfo) {
        const sameScheduleForAllGuests = new Set(userInfo.guests.map(guest => guest.invite)).size === 1;
        const allEveningGuests = userInfo.guests.every(guest => guest.invite === 'evening');

        // Show user's invitation
        let invitationMessage = i18next.t('invite-mix');
        if (userInfo.guests.every(g => g.invite === "day")) {
            invitationMessage = i18next.t('invite-all-day');
        }
        if (userInfo.guests.every(g => g.incite === "evening")) {
            invitationMessage = i18next.t('invite-all-evening');
        }
        const invitation = window.document.getElementById('invitation-content');
        invitation.innerHTML = `` +
            `<p class="mt-5 script fs-2 text-left">${i18next.t('salutation')} ${userInfo.invitees}!</p>` +
            `<p>${invitationMessage}</p>` +
            ((i18next.language === "en" ? userInfo.additionalMessageEng : userInfo.additionalMessageCym) ?? "") +
            `<ul class="list-group mb-3">` +
            userInfo.guests.map(guest => `<span class="rsvp-link">` +
                `<li class="list-group-item d-flex justify-content-between align-items-center px-3 py-2">` +
                    `<div class="text-secondary">` +
                        `<div>${guest.fullName}</div>` +
                        `<div class="script">${i18next.t(guest.invite)}</div>` +
                    `</div> ` +
                    `<span>` +
                        (guest.menuLink ? `<a href="${guest.menuLink}" target="_blank" rel="noopener noreferrer" class="btn btn-primary text-white">${i18next.t("menu-button")}</a> ` : ``) +
                        `<a href="${guest.rsvpLink}" target="_blank" rel="noopener noreferrer" class="btn btn-primary text-white">RSVP</a>` +
                    `</span>` +
                `</li>` +
            `</span>`).join('\n') +
            `</ul>` +
            `<p class="alert alert-primary text-center fw-medium">${i18next.t('request-to-share-photos')} <a target="_blank" rel="noopener noreferrer" href="https://photos.app.goo.gl/HKPjJSx83JXodn3N6">${i18next.t('here')}</a>! 😁🤳📸</p>` +
            `<p class="script fs-3 mt-4">${i18next.t('complimentary-close')},</p>` +
            `<p class="script signature fs-2 mt-4 pb-4 text-left">Soph &amp; Meurig</p>`;

        // Personalise schedule
        [...window.document.getElementById('schedule-items').getElementsByTagName('li')].forEach(item => {
            const guestsForItem = [];
            userInfo.guests.forEach(guest => {
                if (guest.invite === 'day' || guest.invite === 'evening' && eveningItems.includes(item.id)) {
                    guestsForItem.push(guest.name);
                }
            });
            if (guestsForItem.length > 0) {
                if (!sameScheduleForAllGuests) {
                    item.setAttribute('class', 'list-group-item');
                    item.getElementsByClassName('guests')[0].innerHTML =
                        `<span class="fw-medium">${i18next.t('guests')}:</span> <span class="text-secondary fw-medium">${listWithAnd(guestsForItem)}</span>`;
                }
            } else {
                item.setAttribute('class', 'd-none');
            }
        });

        // Update calendar link
        const calendarLink = window.document.getElementById('calendar-link');
        const startTime = allEveningGuests ? '20240427:183000' : '20240427:123000';
        calendarLink.setAttribute("href", `https://calendar.google.com/calendar/event?action=TEMPLATE&text=Sophie+and+Meurig's+Wedding&dates=${startTime}/20240428:000000&location=Coach+House,+Doddington+Hall,+Lincoln,+LN6+4RU`);
    }
}

function showLoadingSpinner(show) {
    window.document.getElementById('loading-spinner').setAttribute('class', show ? 'd-flex justify-content-center mt-5' : 'd-none');
}

function showGuestCodeFinder(show) {
    window.document.getElementById('guest-code-finder').setAttribute('class', show ? 'd-block mt-5 card p-4 border-primary-subtle rounded bg-primary-subtle' : 'd-none');
}

function showHiddenContent(show) {
    window.document.getElementById('hidden-content').setAttribute('class', show ? 'd-block' : 'd-none');
}

function getUserCode() {
    return extractQueryParam(window.location.search, 'code');
}

let userInfo = null;
window.onload = async function() {
    updateTabSelection(targetAnchorOrDefault(window.location.hash));
    showLoadingSpinner(true);
    const userCode = getUserCode();
    if (userCode) {
        try {
            userInfo = await fetchUserInformation(userCode);
            showLoadingSpinner(false);
            showGuestCodeFinder(false);
            showHiddenContent(true);
            setLanguage(getExpressedLanguagePreference() ?? userInfo.defaultLanguage ?? 'en');
            updatePages(userInfo);
        } catch (error) {
            showLoadingSpinner(false);
            window.alert(error.message);
            window.location.replace("/");
        }
    } else {
        showLoadingSpinner(false);
        showGuestCodeFinder(true);
        showHiddenContent(false);
    }
}

const imagePaths = [
    // initial 3 (or 2)
    "/assets/soph-and-meurig-llanddwyn.jpg",
    "/assets/soph-and-meurig-upsidedown.jpg",
    "/assets/soph-and-meurig-fi-wedding.jpg",
    // others
    "/assets/soph-and-meurig-cruise.jpg",
    "/assets/soph-and-meurig-paris.jpg",
    "/assets/soph-and-meurig-bristol.jpg",
    "/assets/soph-and-meurig-sunglasses.jpg",
    "/assets/soph-and-meurig-young.jpg",
    "/assets/soph-and-meurig-punting-2.jpg",
    "/assets/soph-and-meurig-pub.jpg",
    "/assets/soph-and-meurig-christmas.jpg",
    "/assets/soph-and-meurig-punting.jpg",
    "/assets/soph-and-meurig-ben-wedding.jpg",
    "/assets/soph-and-meurig-clubbing.jpg",
    "/assets/soph-and-meurig-beach.jpg",
];
function getNextImagePath() {
    const nextImagePath = imagePaths.pop();
    imagePaths.unshift(nextImagePath);
    return nextImagePath;
}

function allImagesMatch() {
    let allMatching = true;
    let previousImage = null;
    let atLeastOneImageVisible = false;
    [...window.document.getElementById('gallery').getElementsByTagName('img')].forEach((image) => {
        if (previousImage === null) { previousImage = image; }
        atLeastOneImageVisible |= image.checkVisibility();
        if (image.checkVisibility()) {
            allMatching &= image.getAttribute('src') === previousImage.getAttribute('src');
        }
    });
    return allMatching && atLeastOneImageVisible;
}

function checkForFireworks() {
    const body = window.document.getElementsByTagName('body')[0];
    body.setAttribute('class', allImagesMatch() ? 'fireworks' : '');
}

function woof() {
    const imgContainer = window.document.getElementById('lyra-image-container');
    imgContainer.setAttribute('class', imgContainer.getAttribute('class') === 'woof' ? '' : 'woof');
}

window.onhashchange = function() {
    const guestCode = getUserCode();
    if (guestCode) {
        updateTabSelection(targetAnchorOrDefault(window.location.hash));
    } else {
        window.location.replace("/");
    }
}

// Register event handlers
window.document.getElementById('language-selector').addEventListener('change', (event) => {
    setLanguage(event.target.value);
    updatePages(userInfo);
});
window.document.getElementById('code-form').addEventListener('submit', (event) => {
    event.preventDefault();
    const codeInput = window.document.getElementById('code-input');
    window.location.replace(`/?code=${codeInput.value}`);
});
window.document.getElementById('lyra-image-container').addEventListener('click', woof);

[...window.document.getElementById('gallery').getElementsByTagName('img')].forEach(img => img.addEventListener('click', (event) => {
    event.target.setAttribute('src', getNextImagePath());
    checkForFireworks();
}));

// Cron job to woof
setInterval(() => {
    woof();
    setTimeout(woof, 1000);
}, 10000);

// Cron job to change images
setInterval(() => {
    const currentImages = [...window.document.getElementById('gallery').getElementsByTagName('img')];
    const imageToChange = currentImages[Math.floor(Math.random() * currentImages.length)];
    imageToChange.setAttribute('src', getNextImagePath());
    checkForFireworks();
}, 30000);