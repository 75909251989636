import i18next from 'i18next';
import locI18next from 'loc-i18next'

const i18n = i18next.init({
    lng: 'en',
    debug: true,
    resources: {
        en: {
            translation: {
                // Navigation
                "title": "Soph & Meurig's Wedding",
                "footer-message": "We would love to see you there!",

                "invitation": "Invitation",
                "invitation-short": "Invite",
                "schedule": "Schedule",
                "travel": "Travel & Accommodation",
                "travel-short": "Travel & Accommodation",
                "q&a": "Q&A",
                "q&a-short": "Q&A",
                "coming-soon": "Coming soon...",
                "loading": "Loading...",
                "here": "here",
                "unexpected-error": "An unexpected error occurred... Contact Meurig!",

                // Code search
                "code-input-information": "To see information relevant to your invite and a link to RSVP please enter your code.",
                "code-input-placeholder": "Enter your code...",
                "code-submit": "Enter",
                "unknown-code": "The invite code didn't seem to work. Please try again or contact Meurig!",

                // Invitation
                "salutation": "Dear",
                "invite-all-day": "We would love you to join us on Saturday 27th April 2024 at the Coach House, Doddington Hall, Lincoln to help us celebrate our wedding. Ceremony at 13:00, wedding breakfast and evening reception to follow.",
                "invite-all-evening": "We would love you to join us for the evening reception at the Coach House, Doddington Hall, Lincoln on Saturday 27th April 2024 at 18:30, to help us celebrate our marriage.",
                "invite-mix": "We would love you to join us at the Coach House, Doddington Hall, Lincoln on Saturday 27th April 2024 to help us celebrate our wedding.",
                "rsvp-deadline": "Please RSVP by 14 February 2024.",
                "request-to-share-photos":  "Please add your photos of the day to the shared photo album",
                "complimentary-close": "Love",
                "day": "Day guest",
                "evening": "Evening guest",
                "menu-button": "Menu",

                // Schedule
                "add-to-calendar": "Add to Calendar",
                "wedding-day": "Saturday",
                "wedding-date": "27 April, 2024",
                "wedding-location": "Our wedding will take place at the Coach House, Doddington Hall, Lincolnshire.",
                "wedding-timings-tbc": "Times are currently provisional, but should not change substantially.",
                "guests": "Guests",
                "arrive": "Arrive at the ",
                "arrive-details": "Please be seated by 12:45. Travel and parking instructions can be found ",
                "ceremony": "Ceremony",
                "group-photos": "Group photos",
                "drinks-reception": "Drinks reception at the Coach House",
                "wedding-breakfast": "Wedding breakfast",
                "seating-plan": "Seating plan can be found",
                "downtime": "Time to catch up with friends and family",
                "music-and-evening-guests": "Welcome evening guests and music",
                "cake-cutting": "Cake cutting and the first dance",
                "evening-light-bites": "Evening light bites",
                "last-orders": "Last orders at the bar",
                "carriages": "Carriages",

                // Travel
                "doddington-location": "Doddington Hall is situated on the outskirts of Lincoln, six miles from the city centre.",
                "getting-to-lincoln": "Getting to Lincoln",
                "lincoln-by-car": "By car",
                "lincoln-by-car-info": "If travelling from the south, come off the A1 at Newark and take the A46 to Lincoln. From the north, take the A57 exit near Retford. From elsewhere in the Midlands, the A46 links Lincoln with Leicester. The city is 40 miles east of Nottingham on the A46 and 40 miles south of the Humber Bridge on the A15. Lincoln is less than an hour's drive from the M1.",
                "lincoln-by-train": "By train",
                "lincoln-by-train-info": "Direct trains to/from London King's Cross take around two hours. There are also frequent rail connections to other major cities, including Sheffield, Nottingham, Leicester, and Leeds.",
                "getting-to-doddington": "Getting to Doddington Hall",
                "doddington-by-car": "By car",
                "doddington-by-car-info": "Doddington Hall is five miles West of Lincoln on the B1190. It is signposted from the A46 Newark to Lincoln Road. It is also signposted from the A57 between the A1 and Lincoln.",
                "postcode": "Post code:",
                "doddington-by-taxi": "By taxi",
                "doddington-by-taxi-info": "We recommend pre-booking a taxi for the end of the evening (11:30pm). There are a number of local taxi companies that should be able to assist, including:",
                "where-to-stay": "Where to stay",
                "nearby-hotels": "Nearby hotels",
                "nearby-hotels-info": "There are a number of hotels, B&Bs and other accommodation options in and around Lincoln that are a short drive/taxi ride away from Doddington Hall, including:",
                "onsite-accommodation": "Onsite accommodation",
                "onsite-accommodation-info": "A limited amount of accommodation at the venue can be booked through their site, but get in touch beforehand and we might be able to arrange a discount!",

                // Q&A
                "q&a": "Q&A",
                "faq-rsvp": "When is the RSVP deadline?",
                "faq-rsvp-info": "Please RSVP by 14 February 2024.",
                "faq-plus-one": "Can I bring a date?",
                "faq-plus-one-info": "Unfortunately, due to space limitations, only guests named on the invitation will be able to attend.",
                "faq-indoors-or-outdoors": "Is the wedding indoors or outdoors?",
                "faq-indoors-or-outdoors-info": "We are hoping to have the wedding ceremony and reception outdoors, weather permitting.",
                "faq-dress-code": "What should I wear?",
                "faq-dress-code-info": "We don’t have a dress code but would suggest semi-formal attire, for example, suits for men and dresses, dressy separates or jumpsuits for women. As we are hoping for the ceremony to be held in the Coach House's walled garden, we would recommend avoiding stiletto heels.",
                "faq-parking": "Where should I park?",
                "faq-parking-info": "Doddington Hall has complimentary parking opposite the Hall in the main car park, Farm Shop car park and down past the Bike Shop.",
                "faq-photos": "Is it okay to take pictures with our phones and cameras during the wedding?",
                "faq-photos-info": "Yes! We would love you to take lots of photos at our wedding! Our photographer is also very happy to take photos of you, so please let him know if you have any requests!",
                "faq-kids": "Are kids welcome?",
                "faq-kids-info": "Unfortunately, due to space limitations, only guests named on the invitation will be able to attend (except for babes in arms).",
                "faq-dietary-restrictions": "What if I have a dietary restriction?",
                "faq-dietary-restrictions-info": "Please inform us of any dietary requirements when you RSVP.",
                "faq-accommodation": "Can I book accommodation at the venue?",
                "faq-accommodation-info": "There is a limited amount of accommodation remaining at the venue. You can book through their website, but feel free to contact us and we might be able to get you a discount!",
                "faq-gifts": "Do you have a wedding gift list?",
                "faq-gifts-info": "We don’t have a gift list as we have already lived together for eight years, so please don’t feel that you need to bring us a present. Having you there is more than enough of a gift, but if you still wish to give us something to celebrate our marriage then a small gift of your choice, a monetary contribution or a donation to a charity that you care about would be very generous."
            }
        },
        cy: {
            translation: {
                // Navigation
                "title": "Priodas Soph a Meurig",
                "footer-message": "Byddem wrth ein bodd yn eich gweld chi yno!",

                "invitation": "Gwahoddiad",
                "invitation-short": "Gwahoddiad",
                "schedule": "Amserlen",
                "travel": "Cyfarwyddiadau a Llety",
                "travel-short": "Llety",
                "q&a": "Mwy o wybodaeth",
                "q&a-short": "Gwybodaeth",
                "coming-soon": "Yn dod yn fuan...",
                "loading": "Llwytho...",
                "here": "yma",
                "unexpected-error": "Gwall annisgwyliedig... Cysylltwch â Meurig!",

                // Code search
                "code-input-information": "I weld gwybodaeth sy'n berthnasol i'ch gwahoddiad a dolen i RSVP, mewnbynnwch eich côd isod.",
                "code-input-placeholder": "Eich côd...",
                "code-submit": "Gwahoddiad",
                "unknown-code": "Methu adnabod y côd. Triwch eto, os gwelwch yn dda, neu cysylltwch â Meurig!",

                // Invitation
                "salutation": "Annwyl",
                "invite-all-day": "Byddem wrth ein bodd os gallech chi ymuno â ni ddydd Sadwrn 27ain Ebrill 2024, yn y Coach House, Neuadd Doddington, Lincoln i'n helpu ni ddathlu ein priodas. Seremoni am 13:00, wedi ei ddilyn gan brecwast priodas a derbyniad nos.",
                "invite-all-evening": "Byddem wrth ein bodd os gallech chi ymuno â ni ar gyfer y derbyniad nos yn y Coach House, Neuadd Doddington, Lincoln ar ddydd Sadwrn 27ain Ebrill 2024 am 18:30, i'n helpu ni ddathlu ein priodas.",
                "invite-mix": "Byddem wrth ein bodd os gallech chi ymuno â ni yn y Coach House, Neuadd Doddington, Lincoln ar ddydd Sadwrn 27 Ebrill 2024 i'n helpu i ddathlu ein priodas.",
                "rsvp-deadline": "Os gwelwch yn dda, ymatebwch erbyn 14 Chwefror 2024.",
                "request-to-share-photos":  "Cyfranwch i'r albwm lluniau",
                "complimentary-close": "Cariad mawr",
                "day": "Gwestai dydd",
                "evening": "Gwestai nos",
                "menu-button": "Bwydlen",

                // Schedule
                "add-to-calendar": "Ychwanegu i Calendar",
                "wedding-day": "Dydd Sadwrn",
                "wedding-date": "27 Ebrill 2024",
                "wedding-location": "Cynnhelir y briodas yn y Coach House, Neuadd Doddington, Lincolnshire.",
                "wedding-timings-tbc": "Mae'r amseroedd, ar hyn o bryd, yn amodol, ond ni fydd newid sylweddol.",
                "guests": "Gwesteion",
                "arrive": "Cyrraedd y ",
                "arrive-details": "Eistedd erbyn 12:45. Mae cyfarwyddiadau teithio a pharcio ar gael ",
                "ceremony": "Seremoni",
                "group-photos": "Lluniau grŵp",
                "drinks-reception": "Derbyniad diodydd yn y Coach House",
                "wedding-breakfast": "Brecwast priodas",
                "seating-plan": "Cynllun eistedd i'w gael",
                "downtime": "Amser i ddal i fyny gyda ffrindiau a theulu",
                "music-and-evening-guests": "Croeso i westeion nos a'r cerddoriaeth yn cychwyn",
                "cake-cutting": "Torri'r cacen a'r ddawns gyntaf",
                "evening-light-bites": "Pryd ysgafn nos",
                "last-orders": "Galwad olaf yn y bar",
                "carriages": "Amser ymadael",

                // Travel
                "doddington-location": "Mae Neuadd Doddington wedi cael ei leoli ar gyrrion Lincoln, chwe milltir o ganol y dref.",
                "getting-to-lincoln": "Cyrraedd Lincoln",
                "lincoln-by-car": "Mewn car",
                "lincoln-by-car-info": "Os ydych yn teithio o'r de, dewch oddi ar yr A1 yn Newark a chymrwch yr A46 i Lincoln. O'r gogledd, cymerwch allanfa A57 ger Retford. O lefydd eraill yn y Canolbarth, mae'r A46 yn cysylltu Lincoln â Leicester. Mae'r ddinas 40 milltir i'r dwyrain o Nottingham ar yr A46 ac 40 milltir i'r de o Bont Humber ar yr A15. Mae Lincoln yn llai nac awr o'r M1.",
                "lincoln-by-train": "Ar y trên",
                "lincoln-by-train-info": "Mae trenau o King's Cross Llundain yn cymryd tua dwy awr heb newidiadau. Mae cysylltiadau rheilffordd cyson hefyd â dinasoedd mawr eraill, gan gynnwys Sheffield, Nottingham, Leicester, a Leeds.",
                "getting-to-doddington": "Cyrraedd Neuadd Doddington",
                "doddington-by-car": "Mewn car",
                "doddington-by-car-info": "Mae Neuadd Doddington pum milltir i'r gorllewin o Lincoln ar y B1190. Mae arwyddion oddi ar yr A46 rhwng Newark a Lincoln. Mae arwyddion hefyd ar yr A57 rhwng yr A1 a Lincoln.",
                "postcode": "Côd post:",
                "doddington-by-taxi": "Mewn tacsi",
                "doddington-by-taxi-info": "Rydym yn argymell archebu tacsi ymlaen llaw ar gyfer diwedd y noson (11:30yh). Mae yna nifer o gwmnïau tacsi lleol a ddylai fod yn gallu helpu, gan gynnwys:",
                "where-to-stay": "Lle i aros",
                "nearby-hotels": "Gwestai cyfagos",
                "nearby-hotels-info": "Mae nifer o westai, lletyau B&B a dewisiadau llety arall ar gael yn Lincoln a'r ardal cyfagos o Neuadd Doddington, gan gynnwys:",
                "onsite-accommodation": "Llety ar y safle",
                "onsite-accommodation-info": "Mae nifer cyfyngedig o opsiynau llety ar y safle a ellir eu harchebu trwy eu gwefan, ond cysylltwch â ni ymlaen llaw ac efallai fedra ni drefnu pris gwell!",

                // Q&A
                "q&a": "Mwy o wybodaeth",
                "faq-rsvp": "Pryd mae'r dyddiad cau ar gyfer ymateb i'r RSVP?",
                "faq-rsvp-info": "Os gwelwch yn dda, ymatebwch erbyn 14 Chwefror 2024.",
                "faq-plus-one": "A gaf i ddod â partner?",
                "faq-plus-one-info": "Yn anffodus, oherwydd cyfyngiadau lle, dim ond y rhai sydd wedi eu henwi ar y gwahoddiad fydd yn gallu mynychu.",
                "faq-indoors-or-outdoors": "Ai tu fewn neu tu allan y bydd y briodas?",
                "faq-indoors-or-outdoors-info": "Rydym yn gobeithio cael y seremoni priodas tu allan, os bydd y tywydd yn caniatáu.",
                "faq-dress-code": "Beth ddylwn i'w wisgo?",
                "faq-dress-code-info": "Nid oes gennym côd gwisg, ond byddem yn awgrymu gwisg rhan-ffurfiol, er enghraifft, siwtiau i ddynion a ffrogiau, siwtiau undarn neu ddillad ar wahan i ferched. Gan ein bod yn gobeithio i'r seremoni gael ei chynnal yn ardd y coach house, byddem yn argymell osgoi esgidiau stileto.",
                "faq-parking": "Ble ddylwn i barcio?",
                "faq-parking-info": "Mae Neuadd Doddington yn cynnig parcio am ddim gyferbyn â'r Neuadd yn y brif maes parcio, maes parcio'r Siop Fferm ac i lawr y tu draw i'r Siop Beics.",
                "faq-photos": "Ydy hi'n iawn i dynnu lluniau gyda'n ffonau a'n camerau yn ystod y briodas?",
                "faq-photos-info": "Byddem wrth ein boddau i chi dynnu lluniau yn ystod ein priodas! Mae ein ffotograffydd hefyd yn hapus iawn i dynnu lluniau ohonoch chi, felly rhowch wybod iddo os oes gennych unrhyw geisiadau!",
                "faq-kids": "Ydi plant yn cael dod?",
                "faq-kids-info": "Yn anffodus, oherwydd cyfyngiadau lle, dim ond y pobl sydd wedi eu henwi ar y gwahoddiad fydd yn gallu mynychu (heblaw am fabanod ifanc ar gliniau).",
                "faq-dietary-restrictions": "Beth os oes gennyf anghenion dietegol?",
                "faq-dietary-restrictions-info": "Rhowch wybod i ni am unrhyw ofynion dietegol pan fyddwch yn RSVP.",
                "faq-accommodation": "Ga' i archebu llety yn Doddington Hall?",
                "faq-accommodation-info": "Mae nifer cyfyngedig o lety ar y safle. Mae hi'n bosib bwcio trwy eu gwefan, ond cysylltwch â ni ymlaen llaw ac efallai fedra ni drefnu pris gwell!",
                "faq-gifts": "Oes gennych restr anrhegion priodas?",
                "faq-gifts-info": "Nid oes gennym restr anrhegion gan ein bod wedi byw gyda'n gilydd am wyth mlynedd yn barod, felly plis peidiwch â theimlo fel bod angen i chi ddod â anrheg. Mae eich cael chi yno yn fwy na digon o anrheg, ond os ydych chi dal i fod eisiau rhoi rhywbeth i ddathlu ein priodas yna byddai anrheg bach o'ch dewis chi, cyfraniad ariannol neu rhodd i elusen sy'n bwysig i chi yn hael iawn."
            }
        }
    }
});

const localise = locI18next.init(i18next);
function updateContent() {
    localise('html');
}

// Initial run
updateContent();
i18next.on('languageChanged', updateContent);

export function getExpressedLanguagePreference() {
    return localStorage.getItem('language');
}

export function setLanguage(language) {
    localStorage.setItem('language', language);
    i18next.changeLanguage(language);
    window.document.getElementById('language-selector').value = language;
}
